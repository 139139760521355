<template>
  <v-container fluid>
    <!-- editor -->
    <editor :editMode="editMode" :initial="dataItem" @data="save"></editor>
    <!-- end -->
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    editMode: false,
  }),
  methods: {
    save(data) {
      const url = "/call";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          self.loading = false;
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/call-management");
        })
        .catch((err) => {
          console.log(err, "err");
          self.loading = false;
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {},
};
</script>
